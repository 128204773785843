<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/Page-header";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import Select2 from 'v-select2-component';
import SelectImage from "@/components/select-image";
import draggable from 'vuedraggable'

/**
 * Starter component
 */
export default {
  head () {
    return {
      title: `${this.title} | Minton - Nuxtjs Responsive Admin Dashboard Template`,
    };
  },
  data () {
    return {
      isEditMode: false,
      questData: {
        // triggerPage: 1,
        // memberType: 0,
        // payMember: [0, 1, 2],
        name: "",
        description: "",
        questType: null,
        wancoin: null,
        type: null,
        target: null,
        startDate: "",
        endDate: "",
        triggerType: 0,
        linkUrl: "",
        status: null
      },
      triggerPageOptions: [
        { id: 1, name: '預設' },
        { id: 2, name: '補填資料頁' }
      ],
      memberOptions: [
        { id: 0, name: '所有用戶' },
        { id: 1, name: '一般用戶（不含付費會員）' },
        { id: 2, name: '付費會員' }
      ],
      payMemberOptions: [
        { id: 0, name: '言論自由民主制衡 我+1' },
        { id: 1, name: '台灣奮起 我+1' },
        { id: 2, name: '中華民國萬萬歲 我+1' }
      ],
      questOptions: [
        { id: 1, name: '常態任務' },
        { id: 2, name: '活動任務' }
      ],
      typeOptions: [
        { id: 0, name: '每日' },
        { id: 1, name: '每月' },
        { id: 2, name: '帳號綁定任務' },
        { id: 4, name: '限定任務' }
      ],
      triggerTypeOptions: [
        { id: 0, name: '不處理' },
        { id: 1, name: '點選按鈕' },
        { id: 2, name: '點選連結' },
        { id: 3, name: '閱讀新聞' }
      ],
      statusOptions: [
        { id: 0, name: '停止' },
        { id: 1, name: '有效' },
        { id: 2, name: '待上架' }
      ],
      searchTypeOptions: [
        { id: 0, name: '依日區間' },
        { id: 1, name: '依月份區間' },
      ],
      searchData: {
        startDate: null,
        endDate: null,
        byType: 0
      },
      tableData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      selectedStartDate: null,
      selectedEndDate: null,
      fields: [
        {
          key: "target_date",
          label: "執行時間（日）",
          sortable: false,
          class: 'width-auto center'
        },
        {
          key: "complete_count",
          label: "當日完成次數",
          sortable: false,
          class: 'width-auto center'
        },
        {
          key: "send_quy",
          label: "旺幣發送數量",
          sortable: false,
          class: 'width-auto center'
        },
        {
          key: "account_list",
          label: "帳戶名單",
          sortable: false,
          class: 'width-auto center'
        },
        {
          key: "complete_total_count",
          label: "完成攻頂次數",
          sortable: false,
          class: 'width-auto center'
        },
        {
          key: "complete_total_list",
          label: "攻頂帳戶名單",
          sortable: false,
          class: 'width-auto center'
        },
      ],
      saving: false
    };
  },
  computed: {
    title() { return this.isEditMode ? "旺幣任務設定" : "新增旺幣任務" },
    items() {
      return [
        {
          text: "旺幣任務設定",
          href: "/wanciontask",
        },
        {
          text: this.isEditMode ? "旺幣任務設定" : "新增旺幣任務",
          active: true,
        }
      ]
    },
    currentQuestId() { return this.$route.params?.questHashID },
    editQuestType() { return this.questOptions.find(_quest => _quest.id === this.questData.questType)?.name || '' },
    editType() { return this.typeOptions.find(_option => _option.id === this.questData.type)?.name || '' },
    // showPayMemberOptions() { return this.questData.memberType === 0 || this.questData.memberType === 2 }
  },
  mounted () {
    this.isEditMode = this.$route.params?.questHashID
    if (this.isEditMode) {
      this.getWancoinSetting()
    }
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
    Select2,
    SelectImage,
    draggable
  },
  // watch: {
  //   'questData.memberType'(_val) {
  //     if (_val === 1) {
  //       this.questData.payMember = []
  //     } else {
  //       this.questData.payMember = [0, 1, 2]
  //     }
  //   }
  // },
  methods: {
    getWancoinSetting() {
      const vm = this
      this.$root.apis.getWancoinQuestsById(
        this.currentQuestId,
        function(_response) {
          const result = _response.body.data
          vm.questData.name = result?.name || ""
          vm.questData.description = result?.description || ""
          vm.questData.questType = result?.quest_type ?? null
          vm.questData.wancoin = result?.wancoin ??  5
          vm.questData.type = result?.type ?? null
          vm.questData.target = result?.target ?? 10
          vm.questData.startDate = vm.$root.common.getNowTime('Object', result?.start_date)
          vm.questData.endDate = vm.$root.common.getNowTime('Object', result?.end_date)
          vm.questData.triggerType = result?.trigger_type ?? null
          vm.questData.linkUrl = result?.link_url ?? ""
          vm.questData.status = result?.status ?? null
        },
        function(_error) {
          console.error(_error)
          vm.$root.common.showErrorDialog(_error.body.data)
        }
      )
    },
    createQuest() {
      const vm = this
      this.$root.apis.createWancoinQuests(
        function(_response) {
          const questId = _response.body.data
          editQuest(questId)
        },
        function(_error) {
          console.error(_error)
          vm.$root.common.showErrorDialog(_error.body.data)
        }
      )
    },
    isInteger(input) {
      const parsedInput = parseInt(input, 10);
      if (/^[1-9]\d*$/.test(input) && Number.isInteger(parsedInput)) {
        return parsedInput.toString() === input;
      } else {
        return false;
      }
    },
    checkData(_data) {
      // 必填欄位
      const requiredData = ['name', 'description', 'quest_type', 'wancoin', 'type', 'trigger_type', 'target']
      const editModeRequiredData = ['start_date', 'end_date', 'status']
      const checkData = this.isEditMode ? requiredData.concat(editModeRequiredData) : requiredData
      
      const invalidFieldExist = checkData.some(_field => {
        console.log(_data[_field])
        return _data[_field] === '' || _data[_field] === null || _data[_field] === undefined
      })
      if (invalidFieldExist) {
        this.$root.common.showErrorNotify('請確定所有欄位都有填寫完成哦！')
        return false
      }

      // 常態任務日期為必填
      if (_data.quest_type === 2) {
        if (!this.questData.startDate || !_data.end_date) {
          this.$root.common.showErrorNotify('任務開始、結束日期為必填！')
          return false
        }
      }
      // 達成方式選連結、連結為必填
      if (_data.trigger_type === 2 && !_data.link_urls) {
          this.$root.common.showErrorNotify('任務的指定頁面連結為必填！')
        return false
      }
      return true
    },
    onSave() {
      // TODO: phase 2 付費會員串接
      const data = {
        name: this.questData.name,
        description: this.questData.description,
        quest_type: this.questData.questType,
        wancoin: this.questData.wancoin,
        type: this.questData.type,
        target: Number(this.questData.target),
        start_date: this.$root.common.getNowTime('datetime', this.questData.startDate),
        end_date: this.$root.common.getNowTime('datetime', this.questData.endDate),
        trigger_type: this.questData.triggerType,
        link_urls: this.questData.linkUrl,
        status: this.questData.status
      }
      if(!this.checkData(data)) return
      console.log('save', data)
      this.isEditMode ? this.updateQuest(data) : this.createQuest(data)
    },
    createQuest(_data) {
      this.saving = true
      const vm = this
      this.$root.apis.createWancoinQuests(_data,
        function(_response) {
          if (_response.body.code === 400) {
            vm.$root.common.showErrorDialog(_response.body.data)
            return
          }
          vm.$root.common.showSingleNotify('新增成功')
          vm.saving = false
          vm.$router.push('/wanciontask')
        },
        function(_error) {
          console.error(_error)
          vm.$root.common.showErrorDialog(_error.body.data)
          vm.saving = false
        }
      )
    },
    updateQuest(_data) {
      this.saving = true
      console.log(_data)
      const vm = this
      this.$root.apis.updateWancoinQuests(this.currentQuestId, _data,
        function(_response) {
          if (_response.body.code === 400) {
            vm.$root.common.showErrorDialog(_response.body.data)
            return
          }
          vm.$root.common.showSingleNotify('更新成功')
          vm.saving = false
          vm.$router.push('/wanciontask')
        },
        function(_error) {
          console.error(_error)
          vm.$root.common.showErrorDialog(_error.body.data)
          vm.saving = false
        }
      )
    },
    onDateSelect(_date, _range) {
      _range === 'startDate' ? this.selectedStartDate = _date : this.selectedEndDate = _date
      this.searchData[_range] = this.$root.common.getNowTime('date', _date)
    },
    onSelected(_option, _object, _key) {
      console.log(_option, _object, _key)
      if (_object) {
        console.log(this[_object])
        this[_object][_key] = _option.id
      } else {
        this[_key] = _option.id
      }
    },
    // 旺幣任務統計表格
    reloadSearchList () {
      this.$refs.searchTable.refresh()
    },
    searchList (_table, _callback) {
      const vm = this

      const searchParam = {
        start_date: this.searchData.startDate || null,
        end_data: this.searchData.endDate || null,
        // by_type: this.searchData.byType || 0
      }
      this.$root.apis.getWanconinStatistical(searchParam, 
        function (_response) {
          let response = _response.body.data
          vm.totalRows = response.total
          vm.currentPage = response.page
          vm.perPage = response.per_page
          if (typeof _callback === 'function') {
            vm.tableData = response
            _callback(vm.tableData)
          }
        },
        function (_error) {
          console.error(_error)
          vm.$root.common.showErrorDialog(_error.body.data)
          if (typeof _callback === 'function') {
            _callback([])
          }
        })
    },
    exportWanconinStatistical() {
      // TODO:
      // window.open(`${process.env.VUE_APP_BACKEND_BASE_URL}/export/member-list`,'_blank')
    },
    exportAccountList() {
      
    },
    exportCompleteTotalList() {

    }
  }
};
</script>

<template lang="pug">
Layout
  PageHeader(:title="title", :items="items")
  .row
    .col-lg-12.mb-2.title-box
      b-button(variant="secondary" to="/wanciontask") 回上一層
    .col-lg-12.mt-2
      b-card.mb-1(no-body)
        b-card-header(header-tag="header", role="tab")
          .header-row
            .header-title 內容設定

        b-card-body
          .row
            .col-10.row
              //- .col-6
              //-     .form-group.mb-3
              //-       label 任務觸發頁面
              //-       br
              //-       multiselect(
              //-       :value="triggerPageOptions.find(_page => _page.id === questData.triggerPage)",
              //-       @input="(_option) => onSelected(_option, 'questData', 'triggerPage')"
              //-       :options="triggerPageOptions", 
              //-       placeholder="選擇任務觸發頁面"
              //-       track-by="id"
              //-       label="name"
              //-       select-label=""
              //-     )
              .col-6
                .form-group.mb-3
                  label 任務名稱
                  br
                  input.form-control(
                    v-model="questData.name"
                    type="text",
                    placeholder="請輸入名稱"
                  )

              .col-12.mb-2
                .form-group.mb-3
                  label 任務說明
                  br
                  textarea.form-control(
                    v-model="questData.description",
                    rows="3",
                    type="text",
                    placeholder="請輸入說明"
                  )
              .col-6
                .form-group.mb-3
                  label 任務類型
                  br
                  input.form-control(
                    v-if="isEditMode"
                    disabled
                    v-model="editQuestType"
                    type="text"
                  )
                  multiselect(
                    v-else
                    :value="questOptions.find(_quest => _quest.id === questData.questType)",
                    @input="(_option) => onSelected(_option, 'questData', 'questType')"
                    :options="questOptions", 
                    placeholder="選擇任務類型"
                    track-by="id"
                    label="name"
                    select-label=""
                  )
              .col-6
                .form-group.mb-3
                  label 每次完成任務可獲得旺幣數量
                  br
                  input.form-control(
                    :disabled="isEditMode"
                    v-model="questData.wancoin"
                    type="number",
                    placeholder="",
                  )
              .col-6
                .form-group.mb-3
                  label 任務更新頻率
                  br
                  input.form-control(
                    v-if="isEditMode"
                    disabled
                    v-model="editType"
                    type="text"
                  )
                  multiselect(
                    v-else
                    :value="typeOptions.find(_option => _option.id === questData.type)",
                    @input="(_option) => onSelected(_option, 'questData', 'type')"
                    :options="typeOptions", 
                    placeholder="選擇任務更新頻率"
                    track-by="id"
                    label="name"
                    select-label=""
                  )
              .col-6
                .form-group.mb-3
                  label 任務的指定行為完成次數
                  br
                  input.form-control(
                    :disabled="isEditMode"
                    v-model="questData.target"
                    type="number",
                    placeholder=""
                  )
              //- .col-12
              //-   .row
              //-     div(:class="[`${showPayMemberOptions ? 'col-4' : 'col-6'}`]")
              //-       .form-group.mb-3
              //-         label 任務對象
              //-         br
              //-         multiselect(
              //-         :value="memberOptions.find(_member => _member.id === questData.memberType)",
              //-         @input="(_option) => onSelected(_option, 'questData', 'memberType')"
              //-         :options="memberOptions", 
              //-         placeholder="選擇任務對象"
              //-         track-by="id"
              //-         label="name"
              //-         select-label=""
              //-       )
              //-     .col-8(v-if="showPayMemberOptions")
              //-         .form-group.mb-3
              //-           label
              //-           br
              //-           b-form-checkbox-group.mt-2(
              //-             v-model="questData.payMember"
              //-           )
              //-             b-form-checkbox(
              //-               v-for="_option in payMemberOptions"
              //-             :key="_option.id"
              //-             :value="_option.id"
              //-           ) {{ _option.name }}
              .col-6(v-if="isEditMode || (!isEditMode && questData.questType === 2)")
                .form-group.mb-3
                  label 任務開始日期
                  br
                  date-picker(
                    type="datetime"
                    ref="postDate"
                    :is-24="true"
                    v-model="questData.startDate",
                    format="YYYY-MM-DD HH:mm"
                    placeholder="請選擇任務開始日期"
                  )
              .col-6(v-if="isEditMode || (!isEditMode && questData.questType === 2)")
                .form-group.mb-3
                  label 任務結束日期
                  br
                  date-picker(
                    type="datetime"
                    ref="postDate"
                    :is-24="true"
                    v-model="questData.endDate",
                    format="YYYY-MM-DD HH:mm"
                    placeholder="任務結束日期"
                  )
              .col-6
                .form-group.mb-3
                  label 任務達成方式
                  br
                  multiselect(
                    :value="triggerTypeOptions.find(_type => _type.id === questData.triggerType)",
                    @input="(_option) => onSelected(_option, 'questData', 'triggerType')"
                    :options="triggerTypeOptions", 
                    placeholder="選擇任務達成方式"
                    track-by="id"
                    label="name"
                    select-label=""
                  )
              .col-6(v-if="questData.triggerType === 2")
                .form-group.mb-3
                  label 任務的指定頁面連結
                  br
                  input.form-control(
                    v-model="questData.linkUrl"
                    type="text",
                    placeholder="請輸入指定頁面連結"
                  )
              .col-6(v-if="isEditMode")
                .form-group.mb-3
                  label 任務狀態
                  br
                  multiselect(
                    :value="statusOptions.find(_type => _type.id === questData.status)",
                    @input="(_option) => onSelected(_option, 'questData', 'status')"
                    :options="statusOptions", 
                    placeholder="選擇任務狀態"
                    track-by="id"
                    label="name"
                    select-label=""
                  )

          .row.text-center
            .col-12.d-flex.justify-content-end
              b-button.width-lg.mr-1(variant="primary" to="/wanciontask") 取消
              b-button.width-lg(variant="primary" @click="onSave" :disabled="saving") {{ saving ? '儲存中...' : '儲存' }}

    //- 編輯模式
    .col-lg-12.mt-2(v-if="isEditMode")
      //- 搜尋bar
      b-card.mb-1(no-body)
        b-card-body
          form.form-horizontal(role="form")
            .row
              .col-lg-4
                .form-group
                  label 開始時間
                  date-picker(
                    :value="selectedStartDate",
                    @input="(_value) => onDateSelect(_value, 'startDate')"
                    @clear="searchData.startDate = null"
                    append-to-body,
                    lang="en",
                    confirm,
                    format="YYYY-MM-DD",
                    placeholder="請選擇日期區間"
                  )
              .col-lg-4  
                .form-group
                  label 結束時間
                  date-picker(
                    :value="selectedEndDate"
                    @input="(_value) => onDateSelect(_value, 'endDate')"
                    @clear="searchData.endDate = null"
                    append-to-body,
                    lang="en",
                    confirm,
                    format="YYYY-MM-DD",
                    placeholder="請選擇日期區間"
                  )
              .col-lg-4
                .form-group
                  label 狀態
                  multiselect(
                    :value="searchTypeOptions.find(_type => _type.id === searchData.byType)",
                    @input="(_option) => onSelected(_option, searchData.byType)"
                    :options="searchTypeOptions", 
                    deselect-label="Can't remove this value"
                    :allow-empty="false",
                    placeholder="請選擇狀態", 
                    track-by="id"
                    label="name"
                    select-label=""
                  )
            .row
              .col-12.mt-1.d-flex.justify-content-end
                b-button.width-lg(variant="primary" @click="reloadSearchList") 查詢
    
    .col-lg-12.mt-2(v-if="isEditMode")
      //- 編輯表格
      b-card.mb-1(no-body)
        b-card-body
          .header-row.mb-3
            .header-title 旺幣任務統計
            b-button.width-lg(variant="primary" @click="exportWanconinStatistical") 匯出
        //- Table
        .table-responsive.mb-0
          b-table(
            head-variant="light"
            ref="searchTable",
            :items="searchList",
            :fields="fields",
            responsive="sm",
            :per-page="perPage",
            :current-page="currentPage")

            //- 帳戶名單
            template(v-slot:cell(account_list)="data")
              b-button.width-lg(variant="primary" @click="exportAccountList") 匯出名單

            //- 攻頂名單
            template(v-slot:cell(complete_total_list)="data")
              b-button.width-lg(variant="primary" @click="exportCompleteTotalList") 匯出名單

        .row.mb-md-2
          .col-12.row.d-flex.justify-content-end  
            div(class="dataTables_paginate paging_simple_numbers float-right")
              ul.pagination.pagination-rounded.mb-0
                b-pagination(v-model="currentPage", :total-rows="totalRows", :per-page="perPage")

</template>
<style lang="sass" scoped>
.title-box
  display: flex
  justify-content: space-between
  align-items: center
  .addbtn
    display: flex
    justify-content: center
    align-items: center
    .ri-file-add-fill
      font-size: 1.4rem
      line-height: 1.5rem
.card-header
  background: #fff
.label-select
  border: none
  padding: 0
  height: auto
  line-height: 1
  font-weight: 600
.header-row
  display: flex
  align-items: center
  .header-title
    margin: 0
    flex: 1

.tab-group
  // background: rgba(229,231,235,1)
  padding: 0.5rem 1rem
  button.off
    color: rgba(156,163,175,1)
    background: rgba(0,0,0,0)
    border-color: rgba(0,0,0,0)

.form-label-row
  display: flex
  gap: 14px

.add-btn
  width: 100%
  border: dashed 1px
.del-btn
  flex: 1
  display: flex
  align-items: center
  position: relative
  top: 3px
  .btn
    padding: 0rem .3rem
    border-radius: 50px
.img-view
  width: 400px
  height: 300px
  background: #e2e8f0
  border-radius: 5px
  margin-top: 5px
  border: 1px solid #ccc
  display: flex
  align-items: center
  justify-content: center
  position: relative
  i
    font-size: 30px

.gray-box
  background: #f1f5f9
  width: 100%
  padding: 10px
  display: flex
  justify-content: center

::v-deep .select2-container--default
  display: block
  width: 100%
  height: calc(1.5em + 0.9rem + 2px)
  // padding: 0.45rem 0.9rem
  font-size: 0.9rem
  font-weight: 400
  line-height: 1.5
  color: #6c757d
  background-color: #fff
  background-clip: padding-box
  border: 1px solid #ced4da
  border-radius: 0.2rem
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out
  outline: none

::v-deep .select2-selection--single
  border: none
  display: flex
  align-items: center
  height: 100%
.form-label-row
  display: flex
  gap: 14px
::v-deep .table-btn
  text-decoration: underline
  color: #0073b8
  background: none
  border: none
  padding: 0
::v-deep .width-auto
  min-width: 7rem
  white-space: nowrap
  vertical-align: middle
  &.center
    text-align: center

.option-item
  display: flex
  gap: 10px
  width: 100%
  align-items: center
  ::v-deep .custom-control-label::after
    top: 45%
  ::v-deep .custom-control-label::before
    top: 45%
</style>
